(function ($) {
  $(window).load(function () {
    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: false,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: true,

      // drag options
      mouseDrag: false
    })

    $(".slider").owlCarousel({
      // slider settings
      autoplay: false,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });
  });

  $(document).ready(function () {
    require('../../vendor/w3media/framework/assets/js/V2/body-sticky-class');

    // make kasabon in smooth layout sticky
		require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
		$(".w3media-booking-receipt-widget").hcSticky({
			stickTo: ".content-section",
			top: 120,
			responsive: {
				992: {
					disable: true,
				},
			},
		});

    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    $('.js-3w-scroll-to-content').on('click', function (event) {
      event.preventDefault();
      var identifier = $(this).attr('data-target');
      var $element = $(identifier);

      if ($element) {
        $('html, body').animate({scrollTop: $element.offset().top}, 500);
      }
    });
    $(document).on('change', '#f-tommy-zeb-begin-date', function () {
      $(this).parent().parent().find('label').css('display', 'none');
    });
    $(document).on('change', '#f-tommy-zeb-end-date', function () {
      $(this).parent().parent().find('label').css('display', 'none');
    });
    $(document).on('change', '.tommy-zeb-accommodation-type-choice > select', function () {
      $(this).parent().parent().parent().parent().find('h2').css('display', 'none');
    });

    if ($(window).width() < 992) {
      var $selector = $('.footer-logos-section .grid-items');
      var $clone = $selector.clone();
      $selector.parent().append($clone);
    }
  });
})(jQuery);
